import { defineStore } from "pinia";
import { loginData } from '@/Models/responses.model'
import { Rights } from "@/Models/rights.model";
import axios from "axios";

export const useUserStore = defineStore({
  id: "user",
  state: () => ({
    isLoggedIn: false,
    userName: '',
    roleID: 0,
    serverID: 0,
    rights: {} as Rights,
    rightID: 0,
    errorConfigUser: false,
    notConfigUser: false, 
  }),
  getters: {
    getLoggedInStatus(state){
      if(state.isLoggedIn) return true;
      if(sessionStorage.getItem('token')){
        state.isLoggedIn = true;
        return true
      }
      return false
      
    }
  },
  actions: {
    loginUser(data: loginData, rightID: number) {
      this.isLoggedIn = true;
      this.userName = data.name
      this.roleID = rightID;
      this.serverID = data.serverID;
      this.rights = data.rights;
    },
    async hasRight(right: string){
      if(Object.keys(this.rights).length === 0){
        await axios({
          url: "user/rights",
          method: "get"
        })
        .then((res) => this.rights = res.data);
      }
      if (Boolean(this.rights["admin"]) || Boolean(Object(this.rights)[right])) return true; 
      return false;
    }
  },

});
