<template>
  <a-card style="margin-bottom: 28px" :loading="isloading">
    <a-row>
      <a-col :span="8" class="col">
        <a-statistic title="Registriere Spieler" :value="allPlayer.length" />
        <a-divider type="vertical" style="height: 100%" />
      </a-col>
      <a-col :span="8" class="col">
        <a-statistic title="Banns" :value="bans" />
        <a-divider type="vertical" style="height: 100%" />
      </a-col>
      <a-col :span="8" class="col">
        <a-statistic title="Verteilte Punkte" :value="points" />
      </a-col>
    </a-row>
  </a-card>
  <PlayerTable />
</template>

<script lang="ts" setup>
import { computed } from "vue";
import PlayerTable from "../components/Tables/PlayerTable.vue";
import { usePlayerStore } from "../Stores/Player";
import { storeToRefs } from "pinia";

const player = usePlayerStore();
const { isloading, allPlayer } = storeToRefs(player);

const bans = computed(() => {
  return allPlayer.value.filter((pl) => pl.status == "bann").length;
});

const points = computed(() => {
  let counter = 0;
  allPlayer.value.forEach((el) => Number(el.status) > 0  ? counter += Number(el.status): "");
  return counter;
})
</script>

<style scoped>
.col {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
</style>
