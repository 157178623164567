import axios from "axios";
import { defineStore } from "pinia";
import { stdPlayer } from "../Models/stdPlayer.model";
import { useUserStore } from "./User";

export const usePlayerStore = defineStore({
  id: "player",
  state: () => ({
    isloading: false,
    allPlayer: [] as stdPlayer[],
    user: useUserStore()
  }),
  getters: {
  },
  actions: {
    async init() {
      this.isloading = true
        await axios({
          method: "get",
          url: "player/init",
          headers: {
            "Cache-Control": "public, max-age=300",
          },
        })
          .then((res) => {
            if(res.status == 204){
              this.user.notConfigUser = true;
              return
            }
            console.log(res);
            this.allPlayer = res.data.players;
            console.log(this.allPlayer);
            
          })

          .finally(()=>{this.isloading = false})
    },
    search(searchQuerySTD: string, fastMode= false){
      const returnPlayers: stdPlayer[] = [];
      const searchQuery = searchQuerySTD.toLowerCase()
      console.log("searchQuery", searchQuery);
      
      for (let i = 0; i < this.allPlayer.length; i++) {
        const player = this.allPlayer[i];

        if(player.name.toLowerCase().indexOf(searchQuery) != -1 || player.Identifier.toLowerCase().indexOf(searchQuery)  != -1 || player.dc.toLowerCase().indexOf(searchQuery)  != -1){
          console.log("Push Player");
          returnPlayers.push(player);
        }
        if(!fastMode && returnPlayers.length == 10) break 
      }
      return returnPlayers
    }
  },
});
