<template>
  <div class="login-wrapper" style="background-color: #141414;">
    <div style=" height: 500px; width: 500px;">
        <a-typography-title :level="2">RP-Admin Login</a-typography-title>
        <a-typography-text >Bitte gebe deinen Nutzernamen sowie dein Password ein.</a-typography-text>

      <a-form
        :model="formState"
        name="normal_login"
        class="login-form"
        @finish="onSubmit"
        layout="vertical"
        style="margin-top: 30px; ">
        <a-form-item
          label="Username"
          name="userName"
          :rules="[{ required: true, message: 'Bitte geben deinen Username ein.' }, {pattern: new RegExp(/\d-[A-Za-z]+/), message: 'Falsches Format!', trigger: 'blur'}]"
>
          <a-input v-model:value="formState.userName" placeholder="ID-Name" :status="wrongLogin? 'error' : '' ">
            <template #prefix>
              <UserOutlined class="site-form-item-icon" />
            </template>
          </a-input>
        </a-form-item>

        <a-form-item
          label="Password"
          name="password"
          :rules="[{ required: true, message: 'Bitte geben dein Passwort ein.' }]"
          >
          <a-input-password v-model:value="formState.password" placeholder="Password" :status="wrongLogin? 'error' : '' ">
            <template #prefix>
              <LockOutlined class="site-form-item-icon" />
            </template>
          </a-input-password>
        </a-form-item>

        <a-form-item>
          <a-form-item name="remember" no-style>
            <a-checkbox v-model:checked="formState.remember"
              >Username speichern</a-checkbox
            >
          </a-form-item>
          <!-- <a class="login-form-forgot" href="" style="float: right;">Paswort vergessen</a> -->
          <br>
          <br>
          <a-typography-text v-if="wrongLogin" type="danger">Benutzername oder Password falsch!</a-typography-text>
          <a-typography-text v-if="wrongLogin" type="secondary"> <br>Wenn du dein Passwort vergessen hast, wende dich an deinen Admin</a-typography-text>
        </a-form-item>

        <a-form-item>
          <a-button
            type="primary"
            html-type="submit"
            class="login-form-button"
            :loading="formState.loading">
            Log in
          </a-button>
          Oder
          <a href="">jetzt Registrieren!</a>
        </a-form-item>
      </a-form>
    </div>
    <div
      class="img-wrapper"
      style=" height: 500px; width: 500px">
      <img src="../assets/logo.png" style="object-fit: cover" />
    </div>
  </div>


</template>

<script setup lang="ts">
import {  reactive, ref } from "vue";
import { UserOutlined, LockOutlined } from "@ant-design/icons-vue";
import { useUserStore } from '@/Stores/User';
import axios from 'axios'
import { useRouter } from "vue-router";


const user = useUserStore();
const router = useRouter();

interface FormState {
  userName: string;
  password: string;
  remember: boolean;
  loading: boolean
}
const formState = reactive<FormState>({
  userName: "",
  password: "",
  remember: false,
  loading: false
});

const defaultName = localStorage.getItem("Name");
if(defaultName != null){
  formState.userName = defaultName;
  formState.remember= true;
}


const wrongLogin = ref(false)

async function onSubmit(){
    formState.loading = true
    await axios({
        method: "post",
        url: "user/login",
        data: {
          name: formState.userName,
          password: formState.password,
        },
      })
        .then((res) => {
          wrongLogin.value = false
          user.loginUser(res.data, res.data.rights.id)          
          sessionStorage.setItem('token', res.data.toke);
          if (formState.remember == true) {
            localStorage.setItem("Name", formState.userName);
          } else {
            localStorage.removeItem("Name");
          }

          router.push('/home')

          
        })
        .catch((err) => {
          console.log("error", err);
          
          if (err.request.status == 401) {
            return (wrongLogin.value = true);
          }

          // this.$store.dispatch("alerts/errHandler", err);
        });

      formState.loading = false;
}

</script>

<style scoped>
.login-wrapper {
  height: 100vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

}

.img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  filter: drop-shadow( 0 0 80px rgba(38, 0, 255, 0.13));
}
.img-wrapper img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}


</style>
