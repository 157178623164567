<template>
  <a-card>
    <a-input
      v-model:value="searchQuery"
      placeholder="Spielersuche"
      style="width: 200px; margin-bottom: 20px; height: 40px"
      allow-clear>
      <template #prefix>
        <SearchOutlined />
      </template>
    </a-input>
    <a-table :loading="isloading" :columns="columns" :data-source="players">
      <template #bodyCell="{ column, text }">
        <template v-if="column.dataIndex === 'name'">
          <a>{{ text }}</a>
        </template>
        <template v-if="column.dataIndex === 'status'">
          <a-tag v-if="text > 0" color="orange">{{ text }}</a-tag>
          <a-tag v-else-if="text === 'bann'" color="red">Bann</a-tag>
          <a-tag v-else color="green">0</a-tag>
        </template>
      </template>
    </a-table>
  </a-card>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { SearchOutlined } from "@ant-design/icons-vue";
import { usePlayerStore } from "../../Stores/Player";
import { storeToRefs } from "pinia";

const player = usePlayerStore()
const { isloading, allPlayer } = storeToRefs(player)

const searchQuery = ref("");
const players = computed(()=>{
  if(searchQuery.value != ""){
    console.log("search start")
    const players = player.search(searchQuery.value)
    console.log("search end")
    console.log(players);
    
    return players
  }else{
    return allPlayer.value
  }

});


const columns = [
  {
    title: "Name",
    dataIndex: "name",
    width: 400,
  },
  {
    title: "Discord",
    dataIndex: "dc",
    width: 300,
  },
  {
    title: "Punkte",
    dataIndex: "status",
    width: 350,
  },
  {
    title: "Identifier",
    dataIndex: "Identifier",
    width: 500,
  },
];

// const data = ref([
//   {
//     key: 1,
//     name: "Thomas Maier",
//     dc: "",
//     ident: "1234234234132123",
//     status: "20",
//   },
//   {
//     key: 1,
//     name: "Harvey Specter",
//     dc: "captainrex64",
//     ident: "123423234234",
//     status: "0",
//   },
//   {
//     key: 1,
//     name: "Max Mustermann",
//     dc: "test123",
//     ident: "12341231231",
//     status: "bann",
//   },
// ]);

onMounted( async() =>{
  await player.init()
})







</script>
