<template>
  <a-modal
    :open="newPW.showModal"
    title="Neues Passwort"
    centered
    :closable="false"
    ok-text="Schließen"
    cancel-text="Passwort kopieren"
    @ok="newPW.showModal = false"
    @cancel="copyPW">
    <a-result
      status="success"
      title="Neues Passwort erfolgreich erstellt"
      :sub-title="'Das neue Passwort lautet ' + newPW.pw" />
  </a-modal>
  <a-card>
    
    <a-button type="primary" style="margin-bottom: 30px"
      >Neuen User anlegen
    </a-button>

    <a-table :columns="columns" :data-source="allUser">
      <template #headerCell="{ column }">
        <template v-if="column.key === 'Name'">
          <span> Name </span>
        </template>
        <template v-else-if="column.key === 'created_date'">
          <span> Registriert seit </span>
        </template>
      </template>

      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'Name'">
          <a-input
            v-if="editableData[record.ID]"
            v-model:value="editableData[record.ID]['Name']"></a-input>
          <a-typography-text v-else :style="{ color: token.colorPrimary }">
            {{ record.Name }}
          </a-typography-text>
        </template>
        <template v-else-if="column.key == 'created_date'">
          {{ new Date(record.created_date).toLocaleDateString() }}
        </template>
        <template v-else-if="column.key === 'role_name'">
          <a-select
            v-if="editableData[record.ID]"
            style="width: 50%"
            v-model:value="editableData[record.ID]['role_name']">
            <a-select-option
              v-for="right in allRights"
              :key="right.id"
              :value="right.role_name"></a-select-option>
          </a-select>
          <a-typography-text v-else>
            {{ record.role_name }}
          </a-typography-text>
        </template>
        <template v-else-if="column.key === 'status'">
          <span v-if="editableData[record.ID]">
            <a-switch
              v-model:checked="editableData[record.ID]['status']"
              checked-children="Aktiv"
              un-checked-children="Inaktiv" />
            <br />
            <a-typography-text
              v-if="!editableData[record.ID]['status']"
              type="secondary"
              >Der User kann sich jetzt nicht mehr anmelden
            </a-typography-text>
            <a-typography-text
              v-else
              type="secondary"
              style="visibility: hidden">
              Der User kann sich jetzt anmelden
            </a-typography-text>
          </span>

          <a-tag v-else :color="record.status == 1 ? 'green' : 'red'">{{
            record.status == 1 ? "Aktiv" : "Inaktiv"
          }}</a-tag>
        </template>
        <template v-else-if="column.key === 'action'">
          <span>
            <a-tooltip v-if="!editableData[record.ID]" title="User Bearbeiten">
              <a-button type="text" @click="edit(record.ID)">
                <EditOutlined />
              </a-button>
            </a-tooltip>
            <a-popconfirm
              v-else
              ok-text="Ja"
              title="Abbrechen?"
              cancel-text="Nein"
              placement="bottom"
              @confirm="cancelEdit(record.ID)">
              <a-tooltip title="Bearbeiten abbrechen">
                <a-button type="text">
                  <CloseOutlined />
                </a-button>
              </a-tooltip>
            </a-popconfirm>
            <a-button
              v-if="editableData[record.ID]"
              type="text"
              @click="safe(record.ID)">
              <SaveOutlined />
            </a-button>
            <a-popconfirm
              v-if="!editableData[record.ID]"
              ok-text="Ja"
              title="Neues Passwort erstellen?"
              cancel-text="Nein"
              placement="bottom"
              @confirm="generateNewPW(record.ID)">
              <a-tooltip title="Neues Passwort">
                <a-button type="text">
                  <KeyOutlined />
                </a-button>
              </a-tooltip>
            </a-popconfirm>
            <a-popconfirm
              v-if="!editableData[record.ID]"
              ok-text="Ja"
              title="Löschen?"
              cancel-text="Nein"
              placement="bottom"
              @confirm="deleteUser(record.ID)">
              <a-tooltip title="User löschen">
                <a-button type="text">
                  <DeleteOutlined />
                </a-button>
              </a-tooltip>
            </a-popconfirm>
          </span>
        </template>
      </template>
    </a-table>
  </a-card>
</template>

<script setup lang="ts">
import {
  EditOutlined,
  SaveOutlined,
  CloseOutlined,
  DeleteOutlined,
  KeyOutlined,
} from "@ant-design/icons-vue";
import { useTeam } from "../Stores/Team";
import { UnwrapRef, ref, reactive } from "vue";
import { storeToRefs } from "pinia";
import { theme } from "ant-design-vue";
import { user } from "../Models/User.model";
import axios from "axios";
import { message } from "ant-design-vue";

const { useToken } = theme;
const { token } = useToken();

const team = useTeam();
team.getDataReq();

const { allUser, allRights } = storeToRefs(team);

const columns = [
  {
    name: "Name",
    dataIndex: "Name",
    key: "Name",
    width: 200,
  },
  {
    name: "Registriert",
    dataIndex: "created_date",
    key: "created_date",
    width: 300,
  },
  {
    title: "Rolle",
    dataIndex: "role_name",
    key: "role_name",
    width: 500,
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    width: 350,
  },
  {
    title: "Action",
    key: "action",
    width: 200,
  },
];

const editableData: UnwrapRef<Record<string, user>> = reactive({});
function edit(id: number) {
  const index: number = allUser.value.findIndex((user) => user.ID == id);
  editableData[id] = allUser.value[index];
}
function cancelEdit(id: number) {
  delete editableData[id];
}

async function safe(id: number) {
  const user = editableData[id];
  console.table(user);
  const roleID = team.getRoleID(user.role_name);
  await axios({
    method: "patch",
    url: "/user/user",
    data: {
      name: user.Name,
      roleID,
      status: user.status,
      id: user.ID,
    },
  })
    .then(() => {
      message.success(user.Name + " wurde erfolgreich aktualisiert");
    })
    .catch(() => {
      message.error(
        "Ein Fehler ist aufgetreten, der User wurde nicht aktualisiert"
      );
    })
    .finally(() => {
      cancelEdit(id);
    });
}

async function deleteUser(id: number) {
  await axios({
    method: "delete",
    url: "/user/user",
    data: {
      id,
    },
  })
    .then(() => {
      team.deleteUser(id);
      message.success("Der User wurde erfolgreich gelöscht");
    })
    .catch(() => {
      message.error(
        "Ein Fehler ist aufgetreten, der User wurde nicht gelöscht"
      );
    });
}

const newPW = ref({
  showModal: false,
  pw: "",
});

function copyPW() {
  navigator.clipboard.writeText(newPW.value.pw);
  message.success("Erfolgreich kopiert");
}

async function generateNewPW(id: number) {
  await axios({
    method: "patch",
    url: "/user/pw",
    data: {
      id,
    },
  })
    .then((res) => {
      newPW.value.showModal = true;
      newPW.value.pw = res.data.pw;
    })
    .catch(() => {
      message.error("Fehler beim erstellen eines neuen Pasworts");
    });
}
</script>

<style scoped>
tr {
  height: 77.5px !important;

}
</style>
