<template>
  <a-config-provider :theme="{ algorithm: actuallTheme }">
    <a-layout style="min-height: 100vh">
      <the-sidebar
        v-if="!isLoginView"
        :collapsed="sideBarCollapsed"></the-sidebar>
      <a-layout :style="{ marginLeft: marginLayout }">
        <the-header
          v-if="!isLoginView"
          :collapsed="sideBarCollapsed"
          @changeSidebar="sideBarCollapsed = !sideBarCollapsed"
          @changeTheme="changeTheme"></the-header>
        <a-layout-content
          :style="{
            margin: isLoginView ? '' : '0px 16px',
            padding: isLoginView ? '' : '24px',
          }">
          <RouterViewWrapper/>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </a-config-provider>
</template>
<script lang="ts" setup>
import { ref, computed } from "vue";
import { useRoute } from "vue-router";
import { theme } from "ant-design-vue";
import theSidebar from "./components/Layout/TheSidebar.vue";
import TheHeader from "./components/Layout/TheHeader.vue";
import RouterViewWrapper from "./views/RouterViewWrapper.vue";

const sideBarCollapsed = ref<boolean>(false);

const actuallTheme = ref(theme.darkAlgorithm);
function changeTheme(darkTheme: boolean) {
  if (darkTheme) {
    actuallTheme.value = theme.darkAlgorithm;
  } else {
    actuallTheme.value = theme.defaultAlgorithm;
  }
}

const route = useRoute();
const isLoginView = computed(() => {
  if (route.path == "/login") return true;
  return false;
});

const marginLayout = computed(() => {
  if (route.path == "/login") return "0px";
  if (sideBarCollapsed.value) return "80px";
  return "200px";
});
</script>
<style>
body {
  margin: 0;
}
</style>
