<template>
  <router-view></router-view>
  <a-modal :open="notConfigUser" title="Herzlich Willkommen beim RP-Admin" @ok="handleOkConfig" centered :closable="false">
      <template #footer >
        <a-button v-if="hasConfigRight" key="submit" type="primary"  @click="handleOkConfig">Konfiguration</a-button>
      </template>
      <p>Um starten zu können, musst du erst die Konfiguration abschliesen</p>
    </a-modal>
  <a-modal :open="errorConfigUser" title="Fehler beim Abfragen der Datenbank" @ok="handleOkConfig" centered :closable="false">
      <template #footer >
        <a-button v-if="hasConfigRight" key="submit" type="primary"  @click="handleOkConfig">Konfiguration</a-button>
      </template>
      <p>Beim Abfragen der Datenbank deines Projektes ist eine Fehler aufgetreten. Anscheindend stimmt die Konfiguration nicht mehr</p>
    </a-modal>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useUserStore } from "../Stores/User";
import { onMounted, ref, watch } from "vue";
import { Modal } from "ant-design-vue";
import { useRouter } from "vue-router";

const router = useRouter();
const user = useUserStore();
const { errorConfigUser, notConfigUser } = storeToRefs(user);

const hasConfigRight = ref(false)

watch(errorConfigUser, async ()=>{
  hasConfigRight.value = await user.hasRight("configServer");
})
watch(notConfigUser, async ()=>{
  hasConfigRight.value = await user.hasRight("configServer");
})



function handleOkConfig(){
  user.notConfigUser = false;
  user.errorConfigUser = false;
  router.push("/serverConfig")
}


</script>
