import { createApp } from "vue";
import App from "./App.vue";
import axios from "axios";
import { createPinia } from "pinia";
import Antd, { notification } from "ant-design-vue";
import { useUserStore } from "./Stores/User";
import router from "./router";
// import "ant-design-vue/dist/antd.css";

axios.defaults.baseURL = process.env.VUE_APP_API_BASEURL;
axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (err) {
    if (err.response.status == 403) {
      notification.open({
        message: "Information",
        type: "info",
        description:
          "Dir fehlen die Berechtigungen um diese Aktion durchzuführen. Bei Fragen wende dich an deinen Admin.",
      });
    } else if (err.response.status == 500) {
      notification.open({
        message: "Fehler",
        type: "error",
        description:
          "Ein Fehler ist aufgetreten. Bitte melde dich mit dem Code: " +
          err.response.data.index +
          " im Support. Danke",
        duration: 10,
      });
    }
    else if(err.response.status == 406){
      router.push("/login")
      sessionStorage.removeItem('token');
      sessionStorage.clear()
      notification.open({
        message: "Fehlerhafte Anmeldung",
        type: "error",
        description: "Bitte melde dich erneut an",
        duration: 10,
      });
    }
    else if(err.response.status == 410){
      const user = useUserStore();
      user.errorConfigUser = true;
    }

    return Promise.reject(err);
  }
);

const app = createApp(App);
app.use(createPinia());
app.use(Antd);
app.use(router);
app.mount("#app");
