<template>
  <a-layout-sider
    v-bind:collapsed="collapsed"
    collapsible
    :trigger="null"
    :style="{
      overflow: 'auto',
      height: '100vh',
      position: 'fixed',
      left: 0,
      top: 0,
      bottom: 0,
      backgroundColor: token.colorBgContainer,
    }">
    <div class="logo" style="padding-top: 10px" />
    <a-menu mode="inline" v-model:selectedKeys="selectedKeys">
      <a-menu-item key="1">
        <router-link to="/home">
          <HomeOutlined />
          <span>Spielersuche</span>
        </router-link>
      </a-menu-item>
      <a-menu-item  key="2">
        <router-link to="/user">
          <UserOutlined />
          <span>Benutzer Verwaltung</span>
        </router-link>
      </a-menu-item>
      <a-menu-item key="3">
        <upload-outlined />
        <span>nav 3</span>
      </a-menu-item>
    </a-menu>
  </a-layout-sider>
</template>

<script lang="ts" setup>
import { defineProps, ref, computed, onMounted } from "vue";
import { theme } from "ant-design-vue";
import {useUserStore} from "@/Stores/User";
const { useToken } = theme;
const { token } = useToken();
import {
  HomeOutlined,
  UserOutlined,
  UploadOutlined,
} from "@ant-design/icons-vue";
const selectedKeys = ref<string[]>(["1"]);
defineProps(["collapsed"]);

const user = useUserStore();


</script>

<style scoped>

</style>
