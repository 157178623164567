import { defineStore } from "pinia";
import { user } from "../Models/User.model";
import { Rights } from "../Models/rights.model";
import axios from "axios";

export const useTeam = defineStore({
  id: "Team",
  state: () => ({
    allUser: [] as user[],
    allRights: [] as Rights[]
  }),
  getters: {

  },
  actions: {
    async getDataReq(){
        await axios({
            method: "get",
            url: "/user/init",
          }).then((res) => {
            this.allUser = res.data.users;
            this.allRights = res.data.roles;
          });
    },
    async getUser() : Promise<user[]>{
        if(this.allUser  && this.allRights ) return this.allUser
        await this.getDataReq()
        return this.allUser
    },
    async getRoles() : Promise<Rights[]>{
        if(this.allUser && this.allRights ) return this.allRights
        await this.getDataReq()
        return this.allRights
    },
    getRoleID(roleName: string){
      const index = this.allRights.findIndex(role => role.role_name == roleName);
      return this.allRights[index].id
    },
    deleteUser(id: number){
      const index = this.allUser.findIndex(user => user.ID == id);
      this.allUser.splice(index, 1);
    }
  },

});
