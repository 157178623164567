<template>
  <component :is="'a-affix'" :offset-top="0">
    <a-layout-header
      :style="{ backgroundColor: token.colorBgContainer, paddingLeft: '5px' }">
      <a-row style="max-height: 100%; height: 100%">
        <a-col :span="12">
          <menu-unfold-outlined
            v-if="collapsed"
            class="trigger"
            @click="$emit('changeSidebar')" />
          <menu-fold-outlined
            v-else
            class="trigger"
            @click="$emit('changeSidebar')"
        /></a-col>
        <a-col :span="11" class="col">
          <a-auto-complete
            v-model:value="searchQuery"
            :options="allPlayer"
            style="width: 200px"
            :filter-option="filterPLayer">
            <template #option="item">
              <span @click="$router.push(`player/${item.ooc_ID}`)">
                {{ item.name }}
              </span>
                
            </template>
            <a-input placeholder="Spielersuche">
              <template #prefix>
                <SearchOutlined />
              </template>
            </a-input>
          </a-auto-complete>
        </a-col>
        <a-col :span="1" class="col">
          <a-dropdown placement="bottomRight" :arrow="{ pointAtCenter: true }">
            <user-outlined style="font-size: larger" />
            <template #overlay>
              <a-menu>
                <a-menu-item key="0">
                  <a-switch
                    v-model:checked="darkTheme"
                    checked-children="Dark Mode"
                    un-checked-children="Light Mode" />
                </a-menu-item>
                <a-menu-item key="1">
                  <a-row>
                    <LogoutOutlined style="margin-right: 10px" /> Abmelden
                  </a-row>
                </a-menu-item>
                <a-menu-divider />
              </a-menu>
            </template> </a-dropdown
        ></a-col>
      </a-row>
    </a-layout-header>
  </component>
</template>

<script setup lang="ts">
import { theme } from "ant-design-vue";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  SearchOutlined,
  UserOutlined,
  LogoutOutlined,
} from "@ant-design/icons-vue";
import { defineProps, defineEmits, ref, watch } from "vue";
import { stdPlayer } from "@/Models/stdPlayer.model";
import { usePlayerStore } from "@/Stores/Player";
import { storeToRefs } from "pinia";
const { useToken } = theme;
const { token } = useToken();

const emit = defineEmits(["changeSidebar", "changeTheme"]);
defineProps(["collapsed"]);

const player = usePlayerStore();
const { allPlayer } = storeToRefs(player);

const filterPLayer = (input: string, option: stdPlayer) => {
  return option.name.toUpperCase().indexOf(input.toUpperCase()) >= 0;
};
const searchQuery = ref<string>("");

const darkTheme = ref<boolean>(true);
watch(darkTheme, () => {
  emit("changeTheme", darkTheme.value);
});
</script>

<style scoped>
.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}
.trigger:hover {
  color: #1890ff;
}

.col {
  max-height: 100%;
  display: flex;
  justify-content: right;
  justify-content: right;
  align-items: center;
}

.ant-affix header {
  box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05) !important;
  margin: 10px 20px;
  transition: margin 100ms;
}

header {
  transition: margin 100ms;
}
</style>
