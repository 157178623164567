import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from './views/HomeView.vue'
import LoginView from './views/LoginView.vue'
import UserView from "./views/UserView.vue"
import { useUserStore } from './Stores/User'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/user',
    name: 'user',
    component: UserView
  },
]

const router = createRouter({
  history: createWebHistory (),
  routes
})

router.beforeEach((to, from) =>{
const user = useUserStore();

  if( to.name !== 'login' && !user.getLoggedInStatus){
    return { name: 'login'};
  }
});

export default router
